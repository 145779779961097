@import '../../node_modules/selectric/public/selectric.css'

.select
  &__item
    display: block


  .selectric
    padding-right: 14px
    padding-left: 14px
    border: 1px solid $defaults_border

    background-color: #fff
    border-radius: $defaults_radius
    transition: box-shadow $defaults_transition, border $defaults_transition

  .label
    height: 46px
    margin: 0
    font-size: 14px
    line-height: 46px
    color: $defaults_font-color

  .button
    width: 46px
    height: 46px
    background-color: transparent

    &::after
      border: none
      sprite($select_arrow_down)

  .selectric-items
    background: #fff
    border-radius: 0
    box-shadow: none

  .selectric-items li
    padding-right: 14px
    padding-left: 14px
    font-size: 14px

.btn
  display inline-block

  padding: 16px 15px

  font-family: $font_Bebasneue
  font-weight: $font-weight_regular
  font-size: 24px
  line-height: 1

  text-align: center
  vertical-align: baseline
  text-decoration: none
  user-select none
  background-color: transparent

  border-radius: $defaults_radius
  transition: background $defaults_transition

  // primary
  &--primary
    color: #fff
    background-color: $color_major

    &:hover
      background-color: #1c3fd4
    &:active
      box-shadow: 0 3px 4px rgba(28, 63, 212, .35)
    &:disabled
      background-color: #ccc
      box-shadow: nobe
      
  &--transparent
    padding-top: 14px
    padding-bottom: 14px
    border: 2px solid #fff
    letter-spacing: 0.7px
    background: transparent
    transition: background $defaults_transition, border-color $defaults_transition
    &:hover
      border-color: #1c3fd4

  &--wide
    width: 100%

  &-burger
    position: relative
    width: 38px
    height: 38px
    cursor: pointer
    background-color: $color_major
    border-radius: $defaults_radius
    +above('s')
      display: none

    &__bars
      position: absolute
      top: 0
      right: 0
      left: 0
      bottom: 0

      width: 20px
      height: 16px
      margin: auto

    &__bar
      position: absolute
      width: 100%
      height: 2px
      background: #fff

      &--first
        top: 0
      &--middle
        top: 7px
      &--last
        top: 14px

.buttons
  padding-bottom: 12px
  +above('s')
    display: flex
    padding-top: 16px
    padding-bottom: 18px
    justify-content space-between
  +above('m')
    justify-content flex-start

  .btn
    display: block
    width: 290px
    margin: 10px auto
    +above('s')
      width: 186px
      margin-x: 0
    +above('m')
      margin-right: 30px
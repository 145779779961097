.prices
  display block

  &__row
    +above('l')
      display: flex
      justify-content: space-between
      
  &__label
    display: inline-block
    position: relative
    max-width: 260px
    font-size: 18px
    font-weight: 500
    letter-spacing: 0.2px
    color: #888
    +above('s')
      max-width: none
    
    &-wrap
      margin-top: 21px
    
    &-ic
      position: relative
      display: inline-block
      width: 19px
      height: 19px
      margin-left: 8px
      margin-bottom: -4px
      border: 1px solid #888
      border-radius: 50%
      cursor: pointer
      
      &:hover
        .prices__tooltip
          opacity: 1
          visibility: visible
      
      &:before
        content: ''
        position: absolute
        left: 8px
        top: 4px
        width: 1px
        height: 1px
        background: #888
      
      &:after
        content: ''
        position: absolute
        left: 8px
        bottom: 4px
        width: 1px
        height: 6px
        background: #888
        
  &__tooltip
    position: absolute
    right: -1px
    bottom: 100%
    z-index: 20
    width: 251px
    margin: 0 auto 18px
    padding: 15px 10px
    border: 1px solid #ccc
    border-radius: 4px
    font-size: 12px
    font-weight: 400
    letter-spacing: 0.1px
    white-space: normal
    background: #fff
    box-shadow: 0px 1px 5px 0px rgba(97, 97, 97, 0.16)
    color: #888
    opacity: 0
    visibility: hidden
    pointer-events: none
    transition: all 0.3s
    +above('s')
      width: 490px
    +above('m')
      left: -1000px
      right: -1000px
      z-index: 20
      width: 653px
      padding: 25px 28px 25px 30px
    
    &:before
      content: ''
      position: absolute
      left: 0
      top: 0
      z-index: -1
      width: 100%
      height: 100%
      border-radius: 4px
      background: #fff
    
    &:after
      content: ''
      position: absolute
      right: 2px
      top: 100%
      z-index: -2
      width: 6px
      height: 6px
      border: 1px solid #ccc
      margin-top: -1px
      background: #fff
      box-shadow: 0px 1px 5px 0px rgba(97, 97, 97, 0.16)
      transform: rotate(45deg) translateX(-50%)
      +above('m')
        right: auto
        left: 50%
        width: 8px
        height: 8px        
      
    &-item
      padding: 0
      margin-bottom: 11px
      &:last-child
        margin-bottom: 0
    
.important-symbol
  font-family: 'Bebasneue'
  font-weight: 700
  font-size: 24px
  color: $color_major
/*
Stylus variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon_home_width;
}

The large array-like variables contain all information about a single icon
$icon_home = x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet = width height image $spritesheet_sprites;
*/
$arrow_next_name = 'arrow-next';
$arrow_next_x = 0px;
$arrow_next_y = 0px;
$arrow_next_offset_x = 0px;
$arrow_next_offset_y = 0px;
$arrow_next_width = 46px;
$arrow_next_height = 46px;
$arrow_next_total_width = 100px;
$arrow_next_total_height = 46px;
$arrow_next_image = '../images/sprites/arrows.png';
$arrow_next = 0px 0px 0px 0px 46px 46px 100px 46px '../images/sprites/arrows.png' 'arrow-next';
$arrow_prev_name = 'arrow-prev';
$arrow_prev_x = 54px;
$arrow_prev_y = 0px;
$arrow_prev_offset_x = -54px;
$arrow_prev_offset_y = 0px;
$arrow_prev_width = 46px;
$arrow_prev_height = 46px;
$arrow_prev_total_width = 100px;
$arrow_prev_total_height = 46px;
$arrow_prev_image = '../images/sprites/arrows.png';
$arrow_prev = 54px 0px -54px 0px 46px 46px 100px 46px '../images/sprites/arrows.png' 'arrow-prev';
$sp_arrows_width = 100px;
$sp_arrows_height = 46px;
$sp_arrows_image = '../images/sprites/arrows.png';
$sp_arrows_sprites = $arrow_next $arrow_prev;
$sp_arrows = 100px 46px '../images/sprites/arrows.png' $sp_arrows_sprites;

// These "retina group" variables are mappings for the naming and pairing of normal and retina sprites.

// The list formatted variables are intended for mixins like `retinaSprite` and `retinaSprites`.


/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  spriteWidth($icon_home)
}

.icon-email {
  sprite($icon_email)
}

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
spriteWidth($sprite) {
  width: $sprite[4];
}

spriteHeight($sprite) {
  height: $sprite[5];
}

spritePosition($sprite) {
  background-position: $sprite[2] $sprite[3];
}

spriteImage($sprite) {
  background-image: url($sprite[8]);
}

sprite($sprite) {
  spriteImage($sprite)
  spritePosition($sprite)
  spriteWidth($sprite)
  spriteHeight($sprite)
}


// The `retinaSprite` mixin sets up rules and a media query for a sprite/retina sprite.
// It should be used with a "retina group" variable.
//
// The media query is from CSS Tricks: https://css-tricks.com/snippets/css/retina-display-media-query/
//
// $icon_home_group = 'icon-home' $icon_home $icon_home_2x;
//
// .icon-home {
//     retinaSprite($icon_home_group)
// }


spriteBackgroundSize($sprite) {
	background-size $sprite[6] $sprite[7]
}

retinaSprite($retina_group) {
	$normal_sprite = $retina_group[1]
	$retina_sprite = $retina_group[2]
	sprite($normal_sprite)

	@media (-webkit-min-device-pixel-ratio: 2),
				 (min-resolution: 192dpi) {
		spriteImage($retina_sprite)
		spriteBackgroundSize($normal_sprite)
	}
}

/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of Stylus

This must be run when you have at least 2 sprites.
  If run with a single sprite, then there will be reference errors.

sprites($spritesheet_sprites);
*/
sprites($sprites) {
  for $sprite in $sprites {
    $sprite_name = $sprite[9];
    .{$sprite_name} {
      sprite($sprite);
    }
  }
}

// The `retinaSprites` mixin generates a CSS rule and media query for retina groups
// This yields the same output as CSS retina template but can be overridden in Stylus
//
// retinaSprites($retina_groups)

retinaSprites($retina_groups) {
	for $retina_group in $retina_groups {
		$sprite_name = $retina_group[0];
		.{$sprite_name} {
			retinaSprite $retina_group
		}
	}
}

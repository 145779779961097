.input
  display: flex
  padding-right: 18px
  padding-left: 18px
  align-items: center
  border: 1px solid #b8b8b8

  background-color: #fff
  border-radius: $defaults_radius
  transition: border $defaults_transition

  &.js-is-focused
    border-color: $color_accept

  &.js-is-error
    border-color: $color_error

  &__controll
    width: 100%
    height: 54px
    font-family: $defaults_font
    font-weight: $font-weight_regular
    font-size: 18px
    background-color: transparent
    +placeholder()
      color: #b8b8b8

  &__prepend
    .input__icon
      margin-right: 14px

  &__append
    .input__icon
      margin-left: 14px

  &.is-disabled
    opacity: .5

  &.calendar
    .input__icon
      width: 25px
      height: 25px
    .input__controll
      .no-touchevents &::-webkit-inner-spin-button,
      .no-touchevents &::-webkit-calendar-picker-indicator
        display: none
        -webkit-appearance: none
.instagram
  padding: 25px 0
  +above('s')
    padding: 40px 0
  +above('m')
    padding: 68px 0 50px

  &__header
    display: block
    margin-bottom: 13px
    +above('s')
      display: flex
      flex-direction: row
      flex-wrap: nowrap
      justify-content: space-between
      align-items center
      margin-bottom: 45px

    &-title
      padding-bottom: 23px
      line-height: 1.5
      +above('s')
        padding-bottom: 14px
        line-height: 1


  &__title
    display: inline-block
    margin: 0

  &__sub-title
    display: block
    font-size: 18px
    color: #888

  &-header
    text-align: justify

  &-button
    position: relative
    width: 100%
    padding: 18px 20px 14px
    +above('s')
      width: auto

    &__text
      position: relative
      display: inline-block
      padding-right: 54px

    &__icon
      position: absolute
      top: -7px
      right: 0
      width: 34px
      height: 34px
      fill: #fff

  &-social
    text-align: center

    &__value
      margin-top: 10px
      font-size: 12px
      color: #888
      +above('s')
        margin-top: 15px

  &__cards-wrap
    display: flex
    flex-direction: row
    flex-wrap: wrap
    justify-content: flex-start
    align-content: stretch
    align-items: stretch
    margin: 0 -7.5px
    +above('s')
      margin: 0 -10px
    +above('m')
      margin: 0 -15px
  
  &__more
    font-size: 16px
    text-align: center
    color: #888


.card-instagram
  position: relative
  display: block

  &-wrap
    width: 50%
    margin-bottom: 15px
    padding: 0 7.5px
    +above('s')
      margin-bottom: 20px
      padding: 0 10px
    +above('m')
      width: 33.33333333333333%
      margin-bottom: 30px
      padding: 0 15px

  &__image
    display: block
    width: 100%
    max-width: 100%
    border-radius: 4px

  &__overlay
    border-radius: 4px
    opacity: 0
    transition: opacity .4s

  &:hover

    .card-instagram__overlay
      opacity: 1

    .card-instagram__content
      opacity: 1
      transform: translateY(0)

  &__content
    transform: translateY(20px)
    opacity: 0
    transition: all .4s

    .social-count__ic
      margin-right: 15px

    .social-count__value
      min-width: 30px
      text-align: center

    &-wrap
      position: absolute
      top: 0
      left: 0
      z-index: 2
      display: flex
      width: 100%
      height: 100%
      flex-direction: column
      flex-wrap: nowrap
      justify-content: center
      align-items: center
    +above('s')
      display: flex
      flex-direction: row
      flex-wrap: nowrap
      justify-content: flex-start
      align-content: center
      align-items: center

      .social-count__value
        min-width: 0
        text-align: left

      .social-count__ic
        margin-right: 10px

    &-item
      margin-bottom: 20px
      &:last-child
        margin-bottom: 0
      +above('s')
        margin: 0 64px 0 0

      &:last-child
        margin-right: 0
.promo
  padding-top: 25px
  padding-bottom: 25px
  color: #fff
  background: url($path_image + 'bg/promo.jpg') no-repeat 50% 50%
  background-size: cover
  +above('s')
    padding-top: 49px
    padding-bottom: 23px
  +above('m')
    padding-top: 61px
    padding-bottom: 56px

  &__caption
    font-size: 18px
    line-height: 1.7
    +above('m')
      padding-bottom: 15px

  &__title
    +above('m')
      width: 45%
.navigation
  display: none
  text-align: center
  background-color: #fff
  +above('s')
    display: block
    background-color: #f5f5f5

  &.js-is-open
    display: block

  &__carret
    position: absolute
    top: -7px
    right: 25px

    border-left: 8px solid transparent
    border-right: 8px solid transparent
    border-bottom: 7px solid $color_major
    +above('s')
      display: none

  &__list
    margin-right: - gutter
    margin-left: - gutter
    list-style: none
    +above('s')
      display: flex
      margin: 0
  
  &__item
    +above('s')
      margin-right: 50px

  &__link
    display: block
    padding-top: 12px
    padding-bottom: 12px
    font-weight: $font-weight_medium
    font-size: 18px
    color: #6a6a6a
    border-bottom: 1px solid #6a6a6a
    @extends .link
    +above('s')
      height: 74px
      padding: 24px 0
      border-bottom: 5px solid transparent

    &:hover
      color: $defaults_font-color

    &.active
      color: #fff
      background-color: $color_major
      border-color: transparent
      +above('s')
        color: $defaults_font-color
        background-color: transparent
        border-bottom-color: $color_major

  &__socials
    margin-top: 12px
    margin-bottom: 12px
    +above('s')
      margin: 0

  &__wrapper.wrapper
    +above('s')
      display: flex
      width: 748px
      padding-right: 0
      padding-left: 0
      align-items center
      justify-content space-between
    +above('m')
      width: $wrapper_m
      padding-x: gutter
    +above('l')
      width: $wrapper_l
    +above('xl')
      width: $wrapper_xl

  &__close
    margin-right: -15px
    margin-left: -15px
    padding: 15px
    color: #fff
    background-color: $color_major
    +above('s')
      display: none
@import '../../node_modules/animate.css/animate.css'

@keyframes fadeInDownCustom
  from
    opacity: 0
    transform: translate3d(0, -30%, 0)
  to
    opacity: 1
    transform: translate3d(0, 0, 0)

.fadeInDownCustom
  animation-name: fadeInDownCustom

@keyframes button-spin
  from
    transform: rotate(0deg)

  to
    transform: rotate(360deg)
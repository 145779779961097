.wrapper
  margin-x: auto
  padding-x: gutter
  +above('s')
    width: $wrapper_s
  +above('m')
    width: $wrapper_m
  +above('l')
    width: $wrapper_l
  +above('xl')
    width: $wrapper_xl
.recall
  .form__horizontal
    +above('s')
      display: flex
      justify-content space-between

  .form__row
    +above('s')
      width: 100%

    &--last
      +above('s')
        width: auto
        margin-left: 20px

  .form__submit
    width: 100%
    +above('s')
      padding-right: 30px
      padding-left: 30px
.contacts
  font-family: $font_Helveticaneuecyr
  font-weight: $font-weight_medium
  font-size: 16px
  +above('s')
    display: flex
    justify-content space-between
    align-items center
    font-size: 18px
  +above('xl')
    display: block

  &__item
    position: relative
    margin-top: 9px
    padding-left: 30px
    text-decoration: none
    color: $defaults_font-color
    +above('s')
      width: 50%
      margin-top: 0
    +between('l', 'l')
      width: auto
    +above('xl')
      width: 100%
      margin-top: 12px

  &__icon
    position: absolute
    top: 0
    left: 0
    fill: $color_major

    &.marker
      width: 17px
      height: 22px
    &.timer
      width: 20px
      height: 23px

  &__time
    font-weight: $font-weight_roman
    +between('l', 'l')
      display: inline

  &__br
    +between('l', 'l')
      display: none

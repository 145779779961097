.subscribe
  padding: 35px 0 40px 
  color: #fff
  background: $color_major
  +above('l')
    padding-top: 45px
    padding-bottom: 50px
  
  &__wrapper.wrapper
    +above('s')
      display: flex
      flex-direction: row
      flex-wrap: nowrap
      justify-content: flex-start
      align-content: stretch
      align-items: stretch
      width: 748px
      padding-left: 0
      padding-right: 0
    +above('m')
      width: 960px
      padding-left: 15px
      padding-right: 15px
    +above('l')
      width: 1170px
    +above('xl')
      width: 1440px

  &__text
    +above('s')
      position: relative
      flex-grow: 1
      margin-right: 25px
    +above('l')
      margin-right: 93px
      width: 695px
      flex-grow: 0
    +above('xl')
      margin-right: 153px
      
  &__arrows-wrap
    margin: 42px 0
    padding-right: 38px
    display: none
    +above('s')
      display: flex
      flex-direction: row
      flex-wrap: nowrap
      justify-content: space-between
      align-content: flex-start
      align-items: flex-start
      padding-right: 15px
      margin: 40px 0 30px
    +above('m')
      margin-top: 70px
      padding-right: 40px
    +above('l')
      margin-top: 50px
      padding-right: 30px

  &__arrow
    display: inline-block
    margin-right: 5px
    width: 64px
    height: 18px
    background: url(../images/arrow-right.png) left center no-repeat
    background-size: cover
    fill: #fff
      
    &:last-child
      margin-right: 0
      
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3)
      display: none
    
    +above('m')
      &:nth-child(1)
        display: block
    +above('l')
      &:nth-child(2)
        display: block
  
  &__title
    margin: 0 0 14px 0
    padding: 0
    font-size: 86px
    font-family: 'Bebasneue'
    font-weight: 700
    text-transform: uppercase
    line-height: 1
    letter-spacing: 1.6px
    +above('s')
      margin-bottom: 30px
    +above('m')
      margin-bottom: 20px
      font-size: 109px
      letter-spacing: 3.3px
    +above('l')
      margin-bottom: 0
      padding-left: 0
      font-size: 194px
      letter-spacing: 6px
    +above('xl')
      letter-spacing: 7px
    
  &__sub-title
    margin: 0 0 20px
    padding: 0 10px 0 0
    font-weight: 700    
    font-size: 24px
    line-height: (32 / 24)
    +above('s')
      margin-bottom: 0
      padding-right: 0
      padding-left: 6px
      font-size: 33px
      letter-spacing: 0.5px
    
  &__form
    +above('s')
      width: 330px
      flex-shrink: 0

    &-item
      margin: 15px 0
      
      &:first-child
        margin-top: 8px
      
  &__label
    display: none
    font-size: 15px
    +above('s')
      display: block
      position: absolute
      left: 0
      top: 100%
    &-hidden
      margin: 25px 0 10px 0 
      +above('s')
        display: none
      
  &__button
    width: 100%
    padding: 13px 55px
    font-size: 24px
    line-height: (31 / 24)
    letter-spacing: 0.7px
    +above('s')
      padding-right: 64px
      padding-left: 64px

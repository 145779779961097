.social-count
  display: flex
  flex-direction: row
  flex-wrap: nowrap
  justify-content: flex-start
  align-content: center
  align-items: center

  &__ic
    width: 25px
    height: 22px
    flex-shrink: 0
    margin-right: 10px
    fill: #fff
    +above('s')
      width: 30px
      height: 27px
    
    svg
      max-width: 100%
      max-height: 100%

  &__value
    font-weight: 700
    font-size: 18px
    color: #fff
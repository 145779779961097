.form
  display block

  &__title
    padding-bottom: 2px
    font-family: $font_Bebasneue
    font-weight: $font-weight_bold
    font-size: 32px
    line-height: 1
    text-transform: uppercase
    text-align: left
    letter-spacing: 1px
    +above('s')
      font-size: 58px

  &__row
    margin-top: 20px
    margin-bottom: 20px

    &--last
      margin-bottom 0

    &--horizontal
      display: flex
      align-items center

      .form__label
        margin-right: 13px
        margin-bottom: 0

  &__caption
    margin-top: 8px
    margin-bottom: 8px
    font-size: 12px

  &__group
    margin-top: 30px
    margin-bottom: 30px

  &__label
    margin-bottom: 7px
.radiobox
  display flex
  overflow: hidden
  cursor: pointer

  &__input
    display: none

    &:checked + .radiobox__icon
      spritePosition($radio_active)

    &:checked + .radiobox__icon,
    &:checked + .radiobox__icon + .radiobox__label
      opacity: 1

  &__icon
    margin-right: 11px

    &-label
      display: inline-block
      margin-left: 5px
      vertical-align: middle

  &__icon,
  &__label
    opacity: .5


  &-container
    margin-top: 16px
    +above('s')
      margin-left: 32px

@import '../../node_modules/owl.carousel/dist/assets/owl.carousel.css'

.carousel
  margin-bottom: 15px

  .owl-nav
    position: absolute
    top: 0
    left: 0
    z-index: 0

    width: 100%
    height: 1px

  .owl-prev,
  .owl-next
    position: absolute
    top: 78px
    +above('l')
      top: 100px
    +above('xl')
      top: 130px

    &.disabled
      cursor: default
      opacity: .5

  .owl-prev
    left: -23px

  .owl-next
    right: -23px

  .owl-dots
    margin-top: 10px
    text-align: center
    +above('m')
      text-align: right

  .owl-dot
    display: inline-block
    margin-right: 5px
    margin-left: 5px
    vertical-align: middle

    span
      display: block
      width: 9px
      height: 9px
      background-color: #c8c8c8
      border-radius: 50%

    &.active
      span
        width: 15px
        height: 15px
        background-color: $color_major
.header
  padding-bottom: 21px
  background-color: #fff
  +above('s')
    position: relative
    padding-bottom: 0

  &__wrapper.wrapper
    padding-top: 70px
    +above('s')
      display: flex
      width: 748px
      padding-top: 0
      padding-right: 0
      padding-left: 0

      justify-content space-between
      align-items center
      flex-wrap wrap
    +above('m')
      width: $wrapper_m
      padding-x: gutter
    +above('l')
      position: relative
      width: $wrapper_l
      align-items flex-start
    +above('xl')
      width: $wrapper_xl
      flex-wrap nowrap
      align-items flex-start

  &__topbar
    position: fixed
    top: 0
    right: 0
    left: 0
    z-index: $zindex-header

    display: flex
    width: 100%
    height: 70px
    padding-x: gutter
    align-items center
    justify-content space-between
    background-color: #fff
    +above('s')
      position: relative
      z-index: 0

      width: 57%
      height: auto
      margin-bottom: 5px
      padding-x: 0
      background-color: transparent
    +above('s')
      margin-bottom: 0
    +between('l', 'l')
      width: 482px
      order 1
      align-items flex-start
    +above('xl')
      width: 490px

  &__title
    position: relative
    width: 100%
    padding-top: 13px
    padding-bottom: 11px
    line-height: 1.55
    text-align: center
    +above('s')
      width: 230px
      padding-top: 0
      padding-bottom: 0
      line-height: 1.3
      text-align: left
      flex: none
    +between('l', 'l')
      margin-top: 25px
      margin-left: -28px
      order 2
    +above('xl')
      margin-top: 46px
      margin-left: 15px

    &::after
      position: absolute
      right: -15px
      left: -15px
      bottom: 0

      display: block
      content: ''
      border-bottom: 1px solid $defaults_border
      +above('s')
        display: none

  &__button,
  &__contacts
    width: 226px
    margin-x: auto
    +above('s')
      margin-x: 0

  &__button
    display: block
    +between('l', 'l')
      margin-top: 31px
      margin-bottom: 13px
    +above('xl')
      margin-top: 44px
      margin-bottom: 13px

  &__button-wrapper
    +between('l', 'l')
      width: 226px
      order 3

  &__contacts
    margin-top: 13px
    margin-bottom: 13px
    +above('s')
      width: 67%
      margin-top: 26px
      margin-bottom: 10px
    +between('l', 'l')
      position: absolute
      top: 118px
      right: 15px
      left: 282px
      width: auto
    +above('xl')
      width: 240px
      margin-top: 38px

  &__link
    font-size: 18px

  &__navigation
    position: fixed
    top: 70px
    right: 0
    left: 0
    z-index: $zindex-header
    +above('s')
      position: relative
      top: auto
      z-index: auto
      display: block
      margin-top: 18px
    +above('l')
      margin-top: 0

  &__logos
    +between('l', 'l')
      margin-top: 46px
.icon
  display: block

  &--car-logo-bmw
    sprite($bmw_sm)
    +above('s')
      sprite($bmw)

  &--car-logo-mini
    sprite($mini_sm)
    +above('s')
      sprite($mini)

  &--car-logo-rols
    sprite($rols_sm)
    +above('s')
      sprite($rols)

  &--arrow-prev
    sprite($arrow_prev)

  &--arrow-next
    sprite($arrow_next)

  &--datepicker
    width: 25px
    height: 25px
    background: url($path_image + 'svg/datepicker.svg') no-repeat 50% 50%
    background-size: 100% 100%
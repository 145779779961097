.socials
  display block

  &__item
    font-family: $font_Helveticaneuecyr
    font-weight: $font-weight_roman
    font-size: 16px
    +above('s')
      display: flex
      align-items center
    +above('xl')
      justify-content flex-end

  &__link,
  &__icon
    display: block
    width: 31px
    height: 31px
    margin-x: auto
    +above('s')
      width: 38px
      height: 38px
      margin-x: 0

  &__icon
    padding: 6px
    fill: #fff
    background-color: $color_major
    border-radius: $defaults_radius
    transition background-color $defaults_transition
    +above('s')
      padding: 8px

    &:hover
      background-color: #1c3fd4

  &__label
    margin-bottom: 14px
    +above('s')
      margin-right: 10px
      margin-bottom: 0
    +above('l')
      margin-right: 19px
      font-size: 18px


.car
  display block
  overflow: hidden
  +above('s')
    position: relative
    width: 767px
    margin-x: auto
    padding-left: 15px
  +above('m')
    width: $wrapper_m
    padding-right: 15px
  +above('l')
    width: $wrapper_l
    padding-x: 0
  +above('xl')
    width: 1574px

  &__image
    height: 226px
    margin-left: -24px
    +above('s')
      margin-left: 0
    +above('m')
      max-width: 100%
      height: auto
    +above('xl')
      max-width: none

  &__circle
    display: none
    +above('s')
      position: absolute
      z-index: 2
      display: block
      width: 20px
      height: 20px

      background-color: $color_major
      border-radius: 50%
      opacity: .8
      box-shadow: 0 0 0 2px #fff
      transition: box-shadow $defaults_transition, opacity $defaults_transition
    +above('l')
      width: 30px
      height: 30px
      box-shadow: 0 0 0 3px #fff
    +above('xl')
      width: 40px
      height: 40px

    &.js-is-hovered
      opacity: 1
      box-shadow: 0 0 0 23px #fff

    &::before,
    &::after
      position: absolute
      top: 50%
      left: 50%
      display: block
      background-color: #fff

      content: ''
      transform translate(-50%, -50%)

    &::before
      width: 2px
      height: 10px
      +above('xl')
        height: 20px

    &::after
      width: 10px
      height: 2px
      +above('xl')
        width: 20px

    &--diagnostic
      $x = 598px
      $y = 65px
      top: $y
      left: $x
      +above('m')
        $zx = 100
        $zy = 10
        top: $y + $zy
        left: $x + $zx
      +above('l')
        $zx = 260
        $zy = 20
        top: $y + $zy
        left: $x + $zx
      +above('xl')
        $zx = 590
        $zy = 65
        top: $y + $zy
        left: $x + $zx

    &--transmission
      $x = 417px
      $y = 160px
      top: $y
      left: $x
      +above('m')
        $zx = 100
        $zy = 30
        top: $y + $zy
        left: $x + $zx
      +above('l')
        $zx = 210
        $zy = 77
        top: $y + $zy
        left: $x + $zx
      +above('xl')
        $zx = 410
        $zy = 160
        top: $y + $zy
        left: $x + $zx

    &--engine
      $x = 643px
      $y = 75px
      top: $y
      left: $x
      +above('m')
        $zx = 120
        $zy = 15
        top: $y + $zy
        left: $x + $zx
      +above('l')
        $zx = 150
        $zy = 95
        top: $y + $zy
        left: $x + $zx
      +above('xl')
        $zx = 420
        $zy = 150
        top: $y + $zy
        left: $x + $zx

    &--services
      $x = 487px
      $y = 43px
      top: $y
      left: $x
      +above('m')
        $zx = 90
        $zy = 6
        top: $y + $zy
        left: $x + $zx
      +above('l')
        $zx = 220
        $zy = 15
        top: $y + $zy
        left: $x + $zx
      +above('xl')
        $zx = 480
        $zy = 35
        top: $y + $zy
        left: $x + $zx

    &--suspension
      $x = 200px
      $y = 88px
      top: $y
      left: $x
      +above('m')
        $zx = 35
        $zy = 10
        top: $y + $zy
        left: $x + $zx
      +above('l')
        $zx = 80
        $zy = 35
        top: $y + $zy
        left: $x + $zx
      +above('xl')
        $zx = 180
        $zy = 80
        top: $y + $zy
        left: $x + $zx

    &--battery
      $x = 545px
      $y = 113px
      top: $y
      left: $x
      +above('m')
        $zx = 105
        $zy = 25
        top: $y + $zy
        left: $x + $zx
      +above('l')
        $zx = 390
        $zy = -10
        top: $y + $zy
        left: $x + $zx
      +above('xl')
        $zx = 735
        $zy = 40
        top: $y + $zy
        left: $x + $zx

    &--tires
      $x = 253px
      $y = 149px
      top: $y
      left: $x
      +above('m')
        $zx = 45
        $zy = 25
        top: $y + $zy
        left: $x + $zx
      +above('l')
        $zx = 105
        $zy = 65
        top: $y + $zy
        left: $x + $zx
      +above('xl')
        $zx = 225
        $zy = 150
        top: $y + $zy
        left: $x + $zx

    &--turbo
      $x = 69px
      $y = 149px
      top: $y
      left: $x
      +above('m')
        $zx = 10
        $zy = 30
        top: $y + $zy
        left: $x + $zx
      +above('l')
        $zx = 15
        $zy = 75
        top: $y + $zy
        left: $x + $zx
      +above('xl')
        $zx = 80
        $zy = 150
        top: $y + $zy
        left: $x + $zx
@import '../../node_modules/air-datepicker/dist/css/datepicker.min.css'

.datepicker
  z-index: $zindex-modal + 1
  font-family: $font_Arial
  font-size: 14px
  color: $defaults_font-color

  &--nav
    border-bottom: 0

  &--nav-title i
    color: $defaults_font-color

  &--nav-action path
    stroke: $defaults_font-color

  &--day-name
    color: $defaults_font-color
    text-transform: lowercase

  &--cell.datepicker--cell-day
    height: 34px
    border-radius: 50%

    &.-current-
      color: $defaults_font-color
      background-color: #f0f0f0
    &.-selected-
      color: #fff
      background-color: #000

.not-found
  min-height: 100vh
  padding: 12vh 0 50px
  background: $color_major
  color: #fff
  
  &__title
    margin: 0
    padding: 0
    font-family: 'Bebasneue'
    font-size: 110px
    font-weight: 700
    line-height: 1
    text-transform: uppercase
    text-align: center
    +above('s')
      font-size: 152px
    +above('l')
      font-size: 270px
      letter-spacing: 9px
      text-align: left
    
  &__sub-title
    margin: 0 0 20px
    padding: 0
    font-family: 'Bebasneue'
    font-weight: 700
    font-size: 30px
    line-height: 1
    text-transform: uppercase
    letter-spacing: 3px
    text-align: center
    +above('s')
      font-size: 50px
    +above('l')
      margin: 0 0 30px
      font-size: 102px
      text-align: left
    
  &__text
    margin: 0 auto 30px
    padding: 0
    font-size: 16px
    line-height: (31 / 18)
    font-weight: 700
    letter-spacing: 0.2px
    text-align: center
    +above('s')
      font-size: 18px
    +above('l')
      margin: 0 0 50px
      font-size: 18px
      text-align: left
      
  &__button
    padding-left: 30px
    padding-right: 30px
    +above('l')
      padding-left: 63px
      padding-right: 63px
      
    &-wrap
      text-align: center
      +above('l')
        text-align: left
@import '../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.min.css'

.fancybox
  &-toolbar
    left: 0

  &-button
    &--close
      display: block
      margin-right: auto
      margin-left: auto

  &-thumbs__list a:before,
  &-thumbs__list a.fancybox-thumbs-active:before
    display: none

.fancybox-thumbs__list a.fancybox-thumbs-active
  opacity: .7

@media all and (min-width: 800px)
  .fancybox-thumbs
    top: auto
    right: 0
    left: 0
    bottom: 0

    width: auto
    height: 95px
    padding: 10px 10px 5px
    box-sizing: border-box
    background: rgba(0, 0, 0, .3)

  .fancybox-show-thumbs .fancybox-inner
    right: 0
    bottom: 95px


.checkbox
  display flex
  overflow: hidden
  cursor: pointer

  &__input
    display: none

    &:checked + .checkbox__icon
      spritePosition($checkbox_active)

  &__icon
    margin-right: 11px
    flex none


  &-container
    display: none
    margin-top: 16px
    +above('s')
      margin-left: 32px

    &.js-is-open
      display: block

.img-responsive
  max-width 100%
  height auto

.fleft
  float left

.fright
  float right

.text-underline
  text-decoration: underline

.text-center
  text-align: center

.text-right
  text-align: right

.text-left
  text-align: left

.text-nowrap
  white-space nowrap

section
  position relative

main
  position relative
  height 100%
  clear both

header, nav, section, article, aside, footer, main
  display block

.text-danger
  color: $color_error

.text-success
  color: $color_accept

.text-muted
  color: $color_muted

.primary-color-text
  color: $color_major

.s-grid-row
  margin-right: -(gutter/2)
  margin-left: -(gutter/2)

  > *
    margin-top: 0
    margin-bottom: 0

.hidden,
.js-is-hidden
  display: none

.hidden-xs
  +below('xs')
    display: none !important

.hidden-sm
  +between('s', 's')
    display: none !important

.hidden-md
  +between('m', 'm')
    display: none !important

.hidden-lg
  +between('l', 'l')
    display: none !important

.hidden-hg
  +above('l')
    display: none !important
.gridsheet
  +above('s')
    display: flex
    flex-wrap wrap
    justify-content space-between


  &__row
    +above('s')
      display: flex
      width: 100%
      justify-content space-between
      align-items center
      flex-wrap wrap
    +above('l')
      width: 49%

  &__col
    width: 100%
    height: 69vw
    margin: 10px 0
    +above('s')
      width: 48%
      height: 220px
      margin-top: 15px
      margin-bottom: 15px

    &--huge
      +above('s')
        height 470px

  &__group
    +above('s')
      display: flex
      width: 48%
      flex-wrap wrap

    .gridsheet__col
      +above('s')
        width: 100%

.personal
  margin-top: 35px
  text-align: center
  +above('l')
    height: 490px
    margin-top: 108px
    text-align: left

  &__inner
    padding-top: 15px
    padding-bottom: 15px
    background: #f5f5f5 url($path_image + 'bg/personal.jpg') no-repeat 50% 50%
    background-size: cover
    +above('l')
      padding-top: 30px
      padding-bottom: 30px

  &__wrapper
    +above('xl')
      position: relative

  &__user
    display: none
    margin-top: -6px
    padding-right: 35px
    padding-left: 35px
    @extends .img-responsive
    +above('s')
      width: 400px
      height: 427px
      margin-top: -14px
      padding-x: 0
    +above('l')
      position: absolute
      top: -46px
      left: 10px
      width: auto
      max-width: none
      height: auto
      margin-top: 0
    +above('xl')
      left: 75px

  &__caption
    position: relative
    padding: 1px 33px 14px
    text-align: left
    background-color: #fff
    +above('s')
      width: 612px
      margin-right: auto
      margin-left: auto
      padding: 1px 33px 46px 93px
    +above('l')
      margin-right: 0
      margin-left: 0

  &__title
    text-align: left
    +above('s')
      margin-bottom: 34px

  &__subtitle
    margin-bottom: 15px
    font-weight: $font-weight_bold
    +above('s')
      margin-bottom: 41px
      font-size: 18px

  &__about
    line-height: 1.35
    +above('s')
      font-size: 18px
      line-height: 1.7
@import '../../node_modules/remodal/dist/remodal.css'
@import '../../node_modules/remodal/dist/remodal-default-theme.css'


.remodal
  width: 440px
  margin-bottom: 0
  padding: 28px 21px
  background-color: #f5f5f5

  &--small
    width: 290px
    +above('s')
      width: 386px

  &--medium
    width: 290px
    +above('s')
      width: 620px

  &__content
    position: relative

  &__close
    position: absolute
    top: 29px
    right: 23px
    z-index: 10

    display: block
    width: 24px
    height: 24px
    cursor: pointer
    background-color: #fff
    border: 1px solid $defaults_border
    border-radius: $defaults_radius

    &:hover,
    &:active
      background-color: $color_major
      border-color: $color_major

    &::before,
    &::after
      position: absolute
      top: 10px
      left: 4px

      display: block
      width: 14px
      height: 1px
      content: ''
      background-color: $defaults_border

    &::before
      transform rotate(45deg)
    &::after
      transform rotate(-45deg)

  &__title
    padding-bottom: 20px
    font-family: $font_Bebasneue
    font-weight: $font-weight_bold
    font-size: 32px
    line-height: 1
    text-transform: uppercase
    text-align: center
    letter-spacing: 1px
    +above('s')
      font-size: 58px

  &__footer
    border-top: 1px solid $defaults_border


  &-wrapper
    z-index: $zindex-modal

  &-overlay
    z-index: $zindex-modal-backdrop
.howework
  padding-top: 1px
  background-color: #eaeef6
  +above('l')
    padding-bottom: 43px
  +above('xl')
    overflow-x: hidden

  &__head
    margin-bottom: 12px
    +above('s')
      margin-bottom: 29px
    +above('l')
      margin-bottom: 51px
    +above('xl')
      margin-bottom: 31px

  &__caption
    line-height: 1.35
    +above('s')
      font-size: 18px
      line-height: 1.75
    +above('xl')
      width: 75%

  &__list
    width: 300px
    margin-x: auto
    +above('s')
      display: flex
      width: auto
      margin-top: 10px
      margin-right: 20px
      margin-left: 20px
      justify-content space-between
      flex-wrap wrap
    +above('l')
      margin-x: 0
      margin-top: 23px

  &__item
    display: flex
    margin-top: 29px
    margin-bottom: 29px
    padding: 15px
    align-items center
    cursor: pointer
    transition: background-color $defaults_transition
    +above('s')
      width: 45%
      margin-top: 34px
      margin-bottom: 34px
    +above('l')
      width: 25%
      margin-top: 42px
      margin-bottom: 42px

    &.js-is-hovered
      background-color: #d2e3fd

      .howework__icon
        fill: #7d7d7d
      .howework__label
        color: $color_major

  &__icon
    max-width: 90px
    max-height: 90px
    fill: $color_major
    transition fill $defaults_transition

  &__label
    margin-left: 20px
    font-weight: $font-weight_bold
    transition color $defaults_transition
    +above('s')
      font-size: 18px
      line-height: 1.8

  &__car
    margin-top: -9px
    margin-bottom: 7px
    +above('s')
      margin-top: 9px
      margin-bottom: 23px
    +above('l')
      margin-top: 23px
      margin-bottom: 32px
    +above('xl')
      margin-bottom: 34px

  &__buttons
    margin-top: 24px
    +above('s')
      margin-top: 4px
      padding-bottom: 50px
    +above('l')
      margin-top: 0
      padding-top: 0
      padding-bottom: 0

  &__footer
    text-align: center
    +above('s')
      text-align: left
    +above('l')
      display: flex
      align-items center
      padding-bottom: 25px

    .howework__caption
      +above('l')
        width: 250px
        margin-right: 15px
      +above('xl')
        margin-left: 45px




.footer
  display: block
  color: #fff
  background-color: #010222

  &__logo.logo
    width: 98px
    height: 95px
    +above('l')
      padding: 8px
    +above('xl')
      width: 210px
      height: 203px
      padding: 26px

  &__top
    +above('s')
      display: flex
      flex-wrap wrap
      justify-content space-between
    +above('xl')
      flex-wrap nowrap
      justify-content flex-start
      align-items flex-start

  &__column
    position: relative
    display: flex
    justify-content space-between
    align-items center
    +above('xl')
      align-items flex-start

    &::after
      position: absolute
      right: -15px
      left: -15px
      bottom: 0

      content: ''
      border-top: 1px solid #0f367b

    &--top
      padding-right: 37px
      +between('s', 'm')
        display: block
        padding-right: 0
      +above('l')
        width: 26%
        padding-right: 0
        align-items flex-start
      +above('xl')
        width: 27%

      &::after
        top: 95px
        bottom: auto
        +above('s')
          display: none

      .footer__list
        +above('l')
          margin-top: 15px
        +above('xl')
          margin-top: 5px

    &--middle
      padding-top: 21px
      padding-bottom: 7px
      flex-wrap wrap
      +above('s')
        width: 69%
        padding-top: 33px
      +above('l')
        padding-top: 0
        justify-content space-around
      +above('xl')
        width: 39%
        padding-left: 59px
        justify-content space-between

      &::after
        +above('s')
          display: none

      .footer__list
        margin-top: -18px
        +above('l')
          margin-top: 15px
        +above('xl')
          margin-top: 5px

    &--bottom
      display: block
      padding-top: 13px
      padding-bottom: 13px
      +above('s')
        display: flex
        width: 100%
        padding-top: 29px
        padding-bottom: 29px
      +above('xl')
        width: 34%
        padding-top: 0
        padding-left: 73px
        padding-bottom: 0

      &::after
        bottom: 115px
        +above('s')
          display: none

      .footer__list
        margin-x: -9px
        +above('xl')
          margin: 5px 0 0

      .footer__item
        display: inline-block
        margin: 9px
        +above('xl')
          display: block
          margin: 18px 0
          white-space: nowrap

      .footer__button-wrapper
        margin-top: 45px
        margin-bottom: 17px
        +above('s')
          margin-top: 0
          margin-bottom: 0
        +above('xl')
          margin-top: 31px

  &__bottom
    position: relative
    padding-top: 27px
    padding-bottom: 27px
    text-align: center
    border-top: 1px solid #0f367b
    +above('s')
      padding-top: 5px
      padding-bottom: 5px

    &::before
      +above('s')
        position: absolute
        top: -117px
        right: 0
        left: 0
        display: block
        width: 100%
        content: ''
        border-top: 1px solid #0f367b
      +above('xl')
        display: none

    .footer__wrapper
      +above('s')
        display: flex
        align-items center
        justify-content space-between

  &__item
    margin-top: 18px
    margin-bottom: 18px

  &__link
    @extends .link

  &__enroll
    display: inline-block
    margin-top: 15px

  &__button
    width: 230px
    +above('xl')
      display: block

  &__socials
    +above('xl')
      margin-top: 37px

  &__copyright
    margin-bottom: 18px
    +above('s')
      margin-bottom: 0
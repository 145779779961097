html
  text-size-adjust 100%
  textadjust 100%

body
  min-width: 320px
  margin 0
  padding 0

  font-smoothing antialiased
  font-family: $defaults_font
  font-weight: $font-weight_regular
  font-size: 16px
  line-height: 1.4
  color: $defaults_font-color

  background-color: #fff
  overscroll-behavior-y: contain
  +above('xl')
    font-size: 18px

  &.js-is-prevent-scroll
    overflow: hidden

h1, h2, h3, h4, h5, h6, p
  display block
  margin 0
  padding 0

img, input, textarea, button, a
  border none
  outline none

.link
  text-decoration: none
  cursor: pointer
  color: inherit

  &:hover
    text-decoration: underline
    color: inherit

  &--primary
    color: #2b6cc6

    &:hover
      text-decoration: underline
      color: #2b6cc6

p
  padding-bottom: 11px

b
  font-weight: $font-weight_bold


.h1
  font-family: $font_Bebasneue
  font-weight: $font-weight_bold
  font-size: 20px
  color: $defaults_font-color
  text-transform: uppercase
  letter-spacing: 0.5px
  +above('s')
    font-size: 24px

.h2
  padding-bottom: 14px
  font-family: $font_Bebasneue
  font-weight: $font-weight_bold
  font-size: 32px
  line-height: 1.2
  color: #fff
  text-transform: uppercase
  letter-spacing: 0.5px
  +above('s')
    font-size: 58px
    letter-spacing: 1.8px

.h3
  position: relative
  margin-y: 23px
  padding-left: 23px
  font-family: $font_Bebasneue
  font-weight: $font-weight_bold
  font-size: 32px
  line-height: 1.2
  color: $defaults_font-color
  text-transform: uppercase
  letter-spacing: 1px
  overflow: hidden
  +above('s')
    margin-top: 47px
    margin-bottom: 32px
    padding-left: 33px
    font-size: 58px
    letter-spacing: 2px
  +above('m')
    margin-top: 69px
    margin-bottom: 59px
    padding-left: 43px

  &--simple
    padding-left: 0

    &::before
      display: none

  &::before
    position: absolute
    top: -2px
    left: 2px
    margin-right: 10px
    font-family: 'Helveticaneuecyr'
    color: $color_major
    content: '/'

.card
  position: relative
  display: block
  width: 100%
  height: 100%
  overflow: hidden
  background-position: 50% 50%
  background-size: cover
  border-radius: $defaults_radius

  &--partner
    background-image: url($path_image + 'advantage/01.jpg')
  &--work
    background-image: url($path_image + 'advantage/02.jpg')
  &--warehouse
    background-image: url($path_image + 'advantage/03.jpg')
  &--guarantee-official
    background-image: url($path_image + 'advantage/04.jpg')
  &--guarantee
    background-image: url($path_image + 'advantage/05.jpg')
  &--access
    background-image: url($path_image + 'advantage/06.jpg')

  &:hover
    .card__overlay
      background-color: rgba(0, 0, 0, .3)

  &__under,
  &__overlay
    position: absolute
    top: 0
    right: 0
    left: 0
    bottom: 0

    display: block
    width: 100%
    height: 100%

  &__overlay
    z-index: 1
    background-color: rgba(0, 0, 0, .6)
    transition background-color $defaults_transition

  &__over
    position: absolute
    right: 0
    left: 0
    bottom: 0
    z-index: 2

    display: block
    padding: 24px 37px 24px 27px

  &__counter
    display: block
    margin-bottom: 9px
    font-family: $font_Bebasneue
    font-weight: $font-weight_bold
    font-size: 32px
    letter-spacing: .5px
    color: $color_major
    text-transform: uppercase
    +above('s')
      font-size: 58px

  &__caption
    display: block
    font-weight: $font-weight_bold
    font-size: 16px
    color: #fff
    +above('s')
      font-size: 18px

  &-gallery
    display: block
    width: 218px
    overflow: hidden
    border-radius: $defaults_radius
    +above('m')
      width: auto

    &:hover
      .card-gallery__circle
        display: block

    &__circle
      display: none
      +above('s')
        position: absolute
        top: 50%
        left: 50%
        z-index: 2

        width: 30px
        height: 30px

        background-color: $color_major
        border-radius: 50%
        opacity: .8
        box-shadow: 0 0 0 15px #fff
        transform: translate(-50%, -50%)

      +above('xl')
        width: 40px
        height: 40px

      &::before,
      &::after
        position: absolute
        top: 50%
        left: 50%
        display: block
        background-color: #fff

        content: ''
        transform translate(-50%, -50%)

      &::before
        width: 2px
        height: 10px
        +above('xl')
          height: 20px

      &::after
        width: 10px
        height: 2px
        +above('xl')
          width: 20px


  &-price
    position: relative

    display: block
    height: 450px
    margin-bottom: 10px
    overflow: hidden
    font-size: 0
    background-position: 50% 50%
    background-size: cover
    border-radius: $defaults_radius
    +above('s')
      margin-bottom: 30px
      background-position: 50% 35%
    +above('l')
      width: 32%
      background-position: 50% 50%

    &:hover
      .card-price__overlay
        background-color: transparent

    &__overlay
      position: absolute
      top: 0
      right: 0
      left: 0
      bottom: 0
      z-index: 1

      display: block
      width: 100%
      height: 100%

      background-color: rgba(0, 0, 0, .6)
      transition: background-color 400ms

    &__image
      max-width: 100%
      height: auto

    &__caption
      position: absolute
      right: 0
      bottom: 0
      z-index: 2

      padding: 20px 24px
      background-color: rgba(255, 255, 255, .9)

    &__title
      margin-bottom: 15px
      font-weight: $font-weight_bold
      font-size: 18px

    &__price
      height: 64px
      font-family: $font_Bebasneue
      font-weight: $font-weight_bold
      font-size: 24px
      color: $color_major

      span
        font-family: Arial

    &__sub
      display: block
      margin-top: 3px
      font-family: $font_Helveticaneuecyr
      font-weight: $font-weight_regular
      font-size: 12px
      color: #888
      text-transform: lowercase

    &__button
      width: 182px

    &--oil
      background-image: url($path_image + 'prices/01.jpg')
    &--pad
      background-image: url($path_image + 'prices/02.jpg')
    &--diagnostics
      background-image: url($path_image + 'prices/03.jpg')
.location
  position: relative
  padding-top: 1px
  background: url($path_image + 'bg/locations.png') 50% 50%
  background-size: cover

  &__head
    margin-bottom: 0
    +above('s')
      margin-bottom: 17px
    &-text
      margin-right: 24px
    &-link
      font-family: 'Helveticaneuecyr'
      font-size: 12px
      text-transform: none
      text-decoration: none
      letter-spacing: 1.8px
      font-weight: 400
      color: $color_major
      +above('s')
        font-size: 15px
      &:hover
        text-decoration: underline
        
      &-wrap
        display: block
        margin-top: -15px
        +above('s')
          display: inline
          margin-top: 0
        

  &__row
    display: block
    +above('m')
      width: 50%
    +above('l')
      width: auto

  &__list
    +above('s')
      display: flex
      margin-left: 111px
      flex-direction: column
    +above('m')
      display: flex
      flex-wrap: wrap
      margin-left: 0
      justify-content space-between
      flex-direction: row

  &__item
    position: relative
    display: block
    padding-top: 24px

    +above('s')
      display: inline-block
      margin-bottom: 38px
      padding-bottom: 15px
    +above('l')
      margin-bottom: 50px

  &__title
    position: relative
    z-index: 2
    font-family: $font_Bebasneue
    font-weight: $font-weight_bold
    font-size: 62px
    white-space nowrap
    color: rgba(31,120,243,.3);
    text-transform: uppercase
    +above('s')
      font-size: 102px
      letter-spacing: 3px
    +above('m')
      font-size: 82px
    +above('xl')
      font-size: 102px
      letter-spacing: 3px

  &__label
    position: absolute
    top: 69px
    left: 74px
    z-index: 2
    width: 170px
    font-weight: $font-weight_bold
    +above('s')
      top: 107px
      left: 84px
      width: 190px
      font-size: 18px
      line-height: 1.7
    +above('m')
      top: 84px
      left: 64px
      width: 170px
      font-size: 16px
      line-height: 1.5
    +above('xl')
      top: 107px
      left: 84px
      width: 190px
      font-size: 18px
      line-height: 1.7
    &--sm
      +above('l')
        left: 50px
    &--lg
      width: 200px
      +above('s')
        width: 220px

  &__preview
    position: absolute
    top: 0
    right: 0
    z-index: -1

    width: 196px
    height: 130px
    +above('m')
      top: 10px
      width: auto
      height: auto

  &__caption
    +above('s')
      width: 380px
      font-size: 18px
      line-height: 1.8
    +above('m')
      margin-top: -15px
    +above('xl')
      width: 435px
      margin-top: -9px

  &__map
    width: 100%
    height: 300px
    margin-top: 40px
    +above('s')
      height: 340px
      margin-top: 0
    +above('l')
      height: 380px
    +above('xl')
      height: 420px

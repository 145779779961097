.logo
  display: block
  width: 77px
  height: 70px
  padding: 14px 7px
  cursor: pointer
  background-color: #1c69d4
  +above('s')
    width: 148px
    height: 136px
    padding: 12px 17px
  +above('l')
    width: 210px
    height: 192px
    padding: 30px

  &__image
    display: block
    width: 100%
    height: 100%
    fill: #fff
.overlay
  position: fixed
  top: 0
  right: 0
  z-index: $z-overlay

  width: 100%
  height: 100%

  touch-action: pan-y
  background-color: rgba(0, 0, 0, .4)


.designby
  font-weight: $font-weight_light
  +above('s')
    display: flex
    align-items center

  &__label
    margin-bottom: 10px
    +above('s')
      margin-right: 21px
      margin-bottom: 0

  &__logo
    display: block
    width: 163px
    height: 50px
    margin-x: auto

  &__image
    display: block
    width: 100%
    height: 100%
    fill: #fff
.reasons
  display block

  &__maintitle
    margin-bottom: 24px
    +above('s')
      margin-bottom: 33px
    +above('l')
      margin-bottom: 54px

  &__title
    margin-bottom: 22px
    +above('s')
      margin-bottom: 30px
    +above('l')
      margin-bottom: 50px

    .reasons__label
      top: 34px
      left: 75px
      +above('s')
        top: 63px
        left: 82px

    .reasons__counter
      letter-spacing: 1.5px
      +above('s')
        letter-spacing: 2.7px

  &__head
    position: relative

  &__counter
    font-family: $font_Bebasneue
    font-weight: $font-weight_bold
    font-size: 62px
    line-height: 1
    color: $color_major
    text-transform: uppercase
    opacity: .5
    +above('s')
      font-size: 102px

  &__label
    position: absolute
    top: 15px
    left: 0
    z-index: 0
    font-weight: $font-weight_bold
    +above('s')
      top: 30px
      font-size: 18px

  &__list
    +above('s')
      display: flex
      justify-content space-between
      flex-wrap wrap

  &__item
    margin-bottom: 29px
    +above('s')
      width: 43%
      margin-bottom: 42px
    +above('l')
      width: 32%
      margin-bottom: 60px
    +above('xl')
      width: 30%
      margin-bottom: 52px
      padding-right: 50px
      padding-left: 50px

      &:nth-child(3n + 1)
        padding-left: 0
      &:nth-child(3n + 3)
        padding-right: 0

  &__caption
    margin-top: -9px
    +above('s')
      margin-top: -25px
      font-size: 18px
      line-height: 1.75



.logos
  display flex
  justify-content space-between
  align-items center

  &__item
    margin-x: 6px
    +above('s')
      margin-x: 10px

    &:first-child
      margin-left: 0
    &:last-child
      margin-right: 0